<template>
  <div class="">
    <header-portrate />
    <exports-print ref="exportbar" />
    <div>
    <bread-crumbs :items='items'/>
    <TabsComp :tabs="tabs" />
    <v-row class="mainrow mt-2 mb-2" :style="`margin-inline-start:2px;direction:`+lang.dir+`;margin-top:-8px !important;`">
      <v-row class="mt-5">
        <v-col cols="12" md="12">
          <form autocomplete="off">
            <div class="searchTop">
              <div class="searchBar">
                <div>
                  <div class="mobileFont">{{lang.chose_from_list}} {{lang.start_date}}</div>
                  <b-input-group>
                    <b-form-select class="inborder text-center" style="width:33%;background:#efefef;" v-model="sd.day" :options="daysList"/>
                    <b-form-select class="inborder text-center" style="width:33%;background:#efefef;border-right:none !important;" v-model="sd.month" :options="monthsList" />
                    <b-form-select class="inborder text-center" style="width:34%;background:#efefef;" v-model="sd.year" :options="yearsList" />
                  </b-input-group>
                </div>

                <div>
                  <div class="mobileFont">{{lang.chose_from_list}} {{lang.end_date}}</div>
                  <b-input-group>
                    <b-form-select class="inborder text-center" style="width:33%;background:#efefef;" v-model="ed.day" :options="daysList"/>
                    <b-form-select class="inborder text-center" style="width:33%;background:#efefef;border-right:none !important;" v-model="ed.month" :options="monthsList" />
                    <b-form-select class="inborder text-center" style="width:34%;background:#efefef;" v-model="ed.year" :options="yearsList" />
                  </b-input-group>
                </div>

                <div class="SupStatus noInMobile">
                  <div class="mobileFont">{{lang.chose_from_list}} {{lang.superVisor}}</div>
                  <b-input-group>
                    <b-form-select
                      class="selborder"
                      v-model="superVisor"
                      :options="super_visors"
                      ></b-form-select>
                      <b-input-group-append :style="`margin-${lang.algin}:-20px;margin-top:5px;`">
                          <i class="fas fa-arrow-down"></i>
                      </b-input-group-append>
                  </b-input-group>
                </div>

                <div>
                  <div>&nbsp;</div>
                  <b-button variant="light" style="padding-top:2px !important; padding-bottom:2px !important;background:blue;color:#fff;width:75px;" class="btnMobile"  @click="getCurrentCards()">{{lang.search}}</b-button>
                </div>
              </div>
              
            </div>
          </form>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" :style="`padding-`+lang.lalgin+`:35px;direction:` + lang.dir + `;margin-top:-5px;`">
          <v-data-table
              :headers="headers"
              :items="tablerows"
              :search="search"
              :items-per-page="-1"
              width="100%"
              id="MainTable"
              hide-default-footer
              hide-default-header
              fixed-header
          >
          <template slot="body.prepend">  
              <tr>
                  <td v-for="(iHead, indHead) in headers" :key="indHead" 
                    :class="iHead.class + ` text-center `" style="border:1px solid #000 !important;"
                  >
                    {{ iHead.text }}
                  </td>
                </tr>
                <tr>  
                  <td style="font-size:.1rem !important;background:#fff !important;border-inline :none !important;border-top:1px solid #000 !important;" :colspan="tblColspan">&#32;</td>
                  <td style="font-size:.1rem !important;background:#fff !important;border-inline :none !important;border-bottom :2px solid #bbb !important;border-top:1px solid #000 !important;">&#32;</td>
                </tr>
            </template>
            <template v-slot:item="row">
              <tr>
                <td style="width:50px">{{ row.item.cardid }}</td>
                <td style="width:50px">{{ row.item.invoiceid }}</td>
                <td nowrap>{{ row.item.closedDate }}</td>
                <td nowrap>{{ row.item.plate_number }}</td>
                <td nowrap>{{ row.item.ment_total }}</td>
                <td nowrap>{{ row.item.other_profit }}</td>
                <td nowrap>{{ row.item.total }}</td>
              </tr>
            </template>
            <template slot="body.append">
                <tr>
                    <th colspan="2" class="backEmpty"></th>
                    <th class="backBlack">المجموع</th>
                    <th class="backBlack">{{totals.ment}}</th>
                    <th class="backBlack">{{totals.other}}</th>
                    <th class="backBlack">{{totals.total}}</th>
                </tr>
            </template>
          </v-data-table>
          
        </v-col>

      </v-row>
    </v-row>
      <addNewCard ref="createnew" />

    </div>
    <popViewCard ref="viewCard" />
    <Footer />
    <div class="loadingPage" v-if="$store.state.showLoading">
      <v-progress-circular
          :width="3"
          color="green"
          indeterminate
      ></v-progress-circular>
      </div>
  </div>
</template>

<script>
  import Vue from 'vue'
  import axios from 'axios'
  import addNewCard from "@/components/addNewCard.vue"
  import BreadCrumbs from '@/components/breadCrumbs.vue'
  import HeaderPortrate from '@/components/Header-Portrate.vue'
  import Footer from '@/components/Footer.vue'
  import popViewCard from '@/components/popViewCard.vue'
  import ExportsPrint from '@/components/exports-print.vue'
  import TabsComp from '@/components/tabsComp.vue'
  import {SnotifyPosition} from 'vue-snotify'
  export default Vue.extend({
    name: 'Home',

    components: {
        addNewCard,
        BreadCrumbs,
        HeaderPortrate,
        Footer,
        popViewCard,
        ExportsPrint,TabsComp
    },
    data() {
      return {
        pl: {
          c1: '',
          c2: '',
          c3: '',
          text: ''
        },
        totals: {
            ment: 0,
            other: 0,
            total: 0
        },
        superVisor: 0,
        cpage: 0,
        showLoading: false,
        status: 0,
        cardStatus: [],
        workersList: [],
        cardid: '',
        selected:1,
        vin:'',
        dBgcolor:'',
        search: '',
        drow: false,
        page: 1,
        pageCount:0,
        // sdate: '',
        // edate: '',
        mobile: '',
        excel_fields:{},
        
        tablerows: [
          
        ],
        ishome: true,
        loading: false,
        sd: {
          day: 1,
          month: (new Date()).getMonth() + 1,
          year: (new Date()).getFullYear() - 1,
        },
        ed: {
          day: (new Date()).getDate(),
          month: (new Date()).getMonth() + 1,
          year: (new Date()).getFullYear(),
        },
        allSupers: []
      }
    },
    created() {
      this.getStartDate();
      this.getCurrentCards();
    },
    methods: {
      collectSearch(){
          return {
              module: 'jobProfit',
              elements: {
                  sdate: this.sdate,
                  edate: this.edate,
                  superVisor: this.superVisor,
              }
          }
      },
      resetAllValues(){
        this.$refs.createnew.resetValues();
        this.$refs.createnew.getInvSettings();
        this.$refs.createnew.$refs.firstCar.image = '';
      },
      getStartDate(){
        let d = (new Date()).getDate();
        let m = (new Date()).getMonth() + 1;
        let y = (new Date()).getFullYear();
        if(parseInt(d) < 30){
          d = parseInt(30) - parseInt(d);
          if(parseInt(m) == 1){
            m = 12
            y = parseInt(y) - 1
          }
          else if(parseInt(m) > 1){
            m = parseInt(m) - 1;
          }
        }else if(parseInt(d) == 30){
          d = 1;
        }else{
          d = parseInt(d) - parseInt(30);
        }
        this.sd.day = 1;
        this.sd.month = 1;
        this.sd.year = y;
      },
      preparedata(){
        let exdata = {
            list: "cardList",
            title: 'أوامر التشغيل',//this.lang.invoices,
            data: [],
        }
        for(let i=0;i<this.tablerows.length;i++){
            exdata.data.push(this.tablerows[i]);
        }
        // // console.log(exdata);
        this.$refs.exportbar.exportdata = exdata;
        this.$refs.exportbar.sdate = this.sdate;
        this.$refs.exportbar.edate = this.sdate;
        
        this.$refs.exportbar.exporttitle = "";
        this.$refs.exportbar.palte_number = '';
        this.$refs.exportbar.contact_number = '';
      },
      resetAll(){
        // this.$refs.createnew.resetALl();
      },
      iamin(){
        if("params",typeof this.$route.params.select !== 'undefined'){
          this.changeBck(this.$route.params.doit);
          this.selected = this.$route.params.select;
          this.getCurrentCards();
        }else{
          this.changeBck(1)
        }
      },
      changeBck(i){
        // this.sdate = '';
        
      },
      getExcelHead(){
        const head = this.headers;
        
        // for(var i=0;i< head.length;i++){
        //   const x = head[i].text;
        //   const y = head[i].value;
        //   this.excel_fields[x] = y;
        // }
        
      },
      gotoDaily(){
        this.$router.push({path: '/reports/daily-reports'})
      },
      PrintPage(){
        window.print();
      },
      sumField(key) {
        let x = this.tablerows.reduce((a, b) => parseFloat(a) + (parseFloat(b[key]) || 0), 0);
        x = +x *100;
        x = (Math.round(x)/100);
        if(x != 0) return x;
        else return 0;
      },
      async ViewItem(item){
        this.$refs.viewCard.cardid = item.cardid;
        await this.$refs.viewCard.getCard(item.cardid);
      },
      getNext(page){
        this.cpage = parseInt(page) - 1;
        this.getCurrentCards();
      },
      getCurrentCards(){
        const ds = new Date(this.sdate);
        const de = new Date(this.edate);
        if(ds.getTime() > de.getTime()){
            let message = "يجب ان يكون تاريخ البدء اقل من او يساوي تاريخ الانتهاء";
            this.$snotify.error(message, 'تنبيه', {
                timeout: 1000000,
                showProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                position: SnotifyPosition.centerCenter,
                buttons: [
                    {
                        text: this.lang.close, 
                        action: (toast) => {
                            this.$snotify.remove(toast.id); 
                        } 
                    },
                ]
            });
            this.invoice.payment.cash = this.full_ftotal
            if(this.invoice.payment.cash == 0) this.invoice.payment.cash = '';
            return false;
        }
        let cook = this.$cookies.get(this.$store.state.COOKIEPhase);
        const path = this.$router.currentRoute.path;
        let post = new FormData();
        post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
        if(path == "/home1"){
          this.selected = 3;
        }
        post.append('type','getCardProfit'); 
        post.append('auth',cook);
        post.append('lang',this.$cookies.get(this.$store.state.LangCooki))
        let data = {
          datatype: 'all',
          sdate: this.sdate,
          edate: this.edate,
          mobile: this.mobile,
          plate_number: this.plate_number,
        };
        post.append('data[datatype]',data.datatype);
        post.append('data[sdate]',data.sdate);
        post.append('data[edate]',data.edate);
        post.append('data[superVisor]',this.superVisor);
        // post.append('data[status]',this.selected);
        // this.tablerows = [];
        this.$store.state.showLoading = true;
        this.loading = true;
        axios.post(
          this.$store.state.SAMCOTEC.r_path,
          post
        ).then((response) => {
          this.$store.state.showLoading = false;
          const res = response.data;
          this.tablerows = res.results.data;
          this.totals = res.results.total;
          this.allSupers = res.results.all_supers;
          this.loading = false;
        })
      },
      ExportToExcel(){
        //
      },
      
    },
    computed: {
      plate_number: function(){
        if(this.pl.c1 == '' && this.pl.c2 == '' && this.pl.c3 == ''  && this.pl.text == ''){
          return '';
        }
        return this.pl.c1 + " " + this.pl.c2 + " " + this.pl.c3 + " " + this.pl.text
      },
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        statuses: function(){
          let t = [{text: 'الكل',value: 0}];
          Object.keys(this.cardStatus).forEach((key) => {
            t.push({
              text: this.cardStatus[`${key}`][`name_${this.lang.langname}`],value: key
            })
          })
          return t;
        },
        sdate: function() {
          return (new Date(this.sd.year,this.sd.month-1, this.sd.day+1)).toISOString().substring(0,10);
        },
        edate: function() {
          return (new Date(this.ed.year,this.ed.month-1, this.ed.day+1)).toISOString().substring(0,10);
        },
        yearsList: function(){
          const start_year = this.$store.state.licenseType.startYear;
          let t = [{text: this.lang.year, value: -1}];
          for(let i=0;i<100;i++){
            if(parseInt(start_year) + i <= (new Date()).getFullYear()){
              t.push({text: start_year + i, value: start_year+i})
            }
            
          }
          return t
        },
        super_visors: function(){
          let t = [{text: 'الكل' , value: 0}]
          for(let i=0;i<this.allSupers.length;i++){
            t.push({text: this.allSupers[i].full_name, value: this.allSupers[i].id})
          }
          return t;
        },
        daysList: function(){
          return [
            {text: this.lang.day, value: -1},
            {text: 1, value: 1},
            {text: 2, value: 2},
            {text: 3, value: 3},
            {text: 4, value: 4},
            {text: 5, value: 5},
            {text: 6, value: 6},
            {text: 7, value: 7},
            {text: 8, value: 8},
            {text: 9, value: 9},
            {text: 10, value: 10},
            {text: 11, value: 11},
            {text: 12, value: 12},
            {text: 13, value: 13},
            {text: 14, value: 14},
            {text: 15, value: 15},
            {text: 16, value: 16},
            {text: 17, value: 17},
            {text: 18, value: 18},
            {text: 19, value: 19},
            {text: 20, value: 20},
            {text: 21, value: 21},
            {text: 22, value: 22},
            {text: 23, value: 23},
            {text: 24, value: 24},
            {text: 25, value: 25},
            {text: 26, value: 26},
            {text: 27, value: 27},
            {text: 28, value: 28},
            {text: 29, value: 29},
            {text: 30, value: 30},
            {text: 31, value: 31},
          ]
        },
        monthsList: function(){
          return [
            {text: this.lang.chose_month, value: -1},
            {text: 1, value: 1},
            {text: 2, value: 2},
            {text: 3, value: 3},
            {text: 4, value: 4},
            {text: 5, value: 5},
            {text: 6, value: 6},
            {text: 7, value: 7},
            {text: 8, value: 8},
            {text: 9, value: 9},
            {text: 10, value: 10},
            {text: 11, value: 11},
            {text: 12, value: 12},
          ]
        },
        headers: function() {
          let t= [
            {
              text: "رقم الكرت",
              align: 'center',
              filterable: false,
              value: 'cardid',
              sortable: false,
              class: 'pa-0 backBlack'
            },
            {
              text: "رقم الفاتورة",
              align: 'center',
              filterable: false,
              value: 'cardid',
              sortable: false,
              class: 'pa-0 backBlack'
            },
            {
              text: "تاريخ الفاتورة",
              align: 'center',
              filterable: false,
              value: 'customer',
              sortable: false,
              class: 'pa-0 backBlack'
            },
            {
              text: this.lang.plate_number,
              align: 'center',
              filterable: false,
              value: 'customer',
              sortable: false,
              class: 'pa-0 backBlack'
            },
            {
              text: "صافي شغل اليد",
              align: 'center',
              filterable: false,
              value: 'customer',
              sortable: false,
              class: 'pa-0 backBlack'
            },
            {
              text: "صافي الارباح الاخرى",
              align: 'center',
              filterable: false,
              value: 'mobile',
              sortable: false,
              class: 'pa-0 backBlack'
            },
            {
              text: "المجموع",
              align: 'center',
              filterable: false,
              value: 'sdate',
              sortable: false,
              class: 'pa-0 backBlack'
            },
            
          ]
          
          return t;
        },
      items:function() {
          let title = this.$store.state.hometitle;
          const lang = this.$cookies.get(this.$store.state.LangCooki);
          if(lang == "en"){
            title = this.$store.state.hometitle_en;
          }
          return {
            text: title,
            disabled: true,
            href: '/home',
          }
      },
      xstatus: function() {
        return [
          {
              value: 1,
              text: this.lang.inprogress
          },
          {
              value: 3,
              text: this.lang.closed
          },
          {
              value: 2,
              text: this.lang.not_invoiced
          },
        ]
      },
      tabs: function() {
          const menu = this.$store.getters['state'].menu;
          
            const license = this.$store.getters['state'].licenseType.activemenu;
            const alicense = this.$store.getters['state'].licenseType
            let currentMenu = menu[license];
            // console.log("menu",this.$store.getters['state'].licenseType)
            if(!license){
              return false;
            }
            let cMenu = {main: {},sub:[]};
            Object.values(currentMenu).forEach(value =>{
            const cM = localStorage.getItem('currentMenu');
            if(value.main.index == cM){
                cMenu = value.sub;
            }else if(cM == null && value.main.index == 1){
                cMenu = value.sub;
            }
            });
        
            let t = []
            Object.values(cMenu).forEach(value =>{
            
                if(this.$route.path == value.href){
                    value.class = "mytab";
                }else{
                    value.class = "mytab4";
                }
                if(this.lang.langname == 'ar'){
                    value.name = value.arname
                }else{
                    value.name = value.enname
                }
                for(let i =0; i< value.groupid.length;i++){
                    if(value.groupid[i] == this.$store.getters['state'].group_id){
                    if(alicense[value.perms[0]] || value.perms[0] == 'any')
                        t.push(value)
                    }
                }
            
            })
        
            return t;
      }
    },
  })
</script>
<style>
.v-data-table-header__icon{
  display:none !important;
}
.outsearch{
  color:green;
  border:1px solid #ccc;
}
thead th, tfoot th, .tfoot th{
  background: #efefef !important;
  color:#000 !important;
  border:1px solid #000 !important;
  font-size:12px;
  white-space: nowrap;
}
thead th a , thead th i, thead th i a{
  color:#FFF !important;
  white-space: nowrap;
}
tbody td{
  font-size:12px;
  color:#000 !important;
  white-space: nowrap;
  border:1px solid #000;
}
.v-breadcrumbs{
  padding:12px !important;
}
.v-tab--active{
  background:#ccc;
  color:#FFF;
}
.v-tabs-slider-wrapper a{
  color:#FFF !important;
}
tfoot th{
  border:1px solid #000;
}
.close svg {
    width: 74px !important;
    height: 34px !important;
    color: #FFF;
    background: red;
}
header button.close {
    width: 24px !important;
    height: 24px !important;
}
input{
  box-shadow: none !important;
}
button:active,button:focus{
  box-shadow: none !important;
}
._mybtn{
  font-size:.8rem;
  border:0px solid #fff;
  width:100%;
}
._mybtn2{
  font-size:.8rem;
  background:rgb(15, 212, 230) !important;
  border:0px solid #000;
  width:100%;
}
._mybtn4{
  font-size:.8rem;
  background:rgb(15, 90, 230) !important;
  border:0px solid #fff;
}
._mybtn3{
  font-size:.8rem;
  background:#198754 !important;
  border:0px solid #fff;
}
._mybtn1{
  font-size:.8rem;
  background:#fe7b33 !important;
  border:0px solid #fff;
}
td,th{
      height: 30px !important;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > tbody > tr > th, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > th, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th{
  padding: 0 10px !important;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > tbody > tr > th, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > th, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
    padding: 0 10px !important;
}
.viewBTN{
  font-size:.8rem;
  background:blue !important;
  width:100%;
  color:#fff !important;
  height:25px !important;
  margin-left:5px;
  text-align:center;
  display:flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
}
.viewBTN:hover,.viewBTN:active,.viewBTN.viewBTN:click{
  background:blue !important;
  width:100%;color:#fff !important;
}
.backBlue{
  background: blue !important;
  color: #fff !important;
  border-bottom: 1px solid #fff !important;
  border-left: 1px solid #fff !important;
  border-right: 1px solid #fff !important;
}
.backGold{
  background: gold !important;
  color: #000 !important;
  border-bottom: 1px solid #fff !important;
  border-left: 1px solid #fff !important;
  border-right: 1px solid #fff !important;
}
.viewBTN{
  font-size:.8rem;
  background:blue !important;
  width:100%;
  color:#fff !important;
  height:25px !important;
  margin-left:5px;
  text-align:center;
  display:flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
}
.viewBTN:hover,.viewBTN:active,.viewBTN.viewBTN:click{
  background:blue !important;
  width:100%;color:#fff !important;
}
.loadingPage{
  position: fixed;
  left:0;
  right:0;
  top:0;
  bottom: 0;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  background:#ffffff00;
  z-index: 100000;
}
.plateText{
  width:30px !important;
}
.plateNumbers{
  width:60px !important;
}
.SupStatus{
  width:150px;
}
.addMobile{
  width:165px;
}
@media  only screen and (max-width: 1100px){
  .addMobile{
    width:150px;
    white-space:nowrap;
  }
  .mobileFont, .btnMobile, .btn{
    font-size:.65rem;
  }
  .SupStatus{
    width:130px;
  }
  .plateText{
    width:30px !important;
  }
  .plateNumbers{
    width:60px !important;
  }
  .searchBar div{
    margin-inline-end:1px;
  }
  .noInMobile{
    display:none;
  }
}
</style>

